.navBackground {
    background: #201627 !important;
}
.navIcon {
    color: white !important;
}
.hrLine {
    background-image: linear-gradient(
        269.56deg,
        #f27acd 0.22%,
        #dcd599 51.99%,
        #74dee4 99.62%
    );
    height: 2px !important;
    opacity: 1;
}
@media screen and (max-width: 600px) {
    .navPadding {
        padding: 10px 25px;
    }
}
