.dropdownContainer {
    position: relative;
}
.menu {
    background: #ffffff;
    /* border-radius: 8px; */
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menuActive {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    border-bottom: 1px solid #dddddd;
}

.menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
}

.menuTrigger {
    background: #ffffff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); */
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
}

.menuTrigger img {
    border-radius: 90px;
}

.dropdownButton {
    border: none;
    cursor: pointer;
    background: #ffffff;
    border-radius: 50px;
    overflow: hidden;
    width: 45px;
    height: 45px;
}

.dropdownBox {
    top: 50px;
    right: 0px !important;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;

    border-radius: 24px;
    border: 1px solid #f27acd;
    background: rgba(254, 254, 254, 1);

    width: max-content;
}
