/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap'); */

html,
body {
    margin: 0;
    padding: 0;
    background: #fefefe;
    display: flex;
    font-family: 'Ubuntu', sans-serif !important;
}

* {
    scrollbar-width: thin;
    scrollbar-color: gray transparent !important;
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 3px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

.thead tr {
    border-style: hidden !important;
    background: #f1eef1 !important;
    border-radius: 8px !important;
}

.h-600px {
    min-height: 600px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-black {
    color: #201627 !important;
}

.color-greyy {
    color: #565159 !important;
}
.color-grey {
    color: #747076 !important;
}

.color-pink {
    color: #e54fc4 !important;
}
.border-pink {
    border: 1px solid #e54fc4 !important;
}

.text-greyy {
    color: rgba(116, 112, 118, 1) !important;
}

.bg-teal {
    background: #6bd6d6;
}

.bg-light-gray {
    background: #f1eef1;
}

.color-light-gray {
    color: #bdbdbd !important;
}

.bg-pink {
    background: #e54fc4;
    border: 1px solid #e54fc4;
}

.bg-purple {
    background: #a12ceb !important;
}

.border-blackk {
    border: 1px solid #383639 !important;
}

.color-purple {
    color: #a12ceb !important;
}

.color-gray-5 {
    color: #383639 !important;
}

.color-teal {
    color: #6bd6d6 !important;
}

.color-white {
    color: #fefefe !important;
}

.main-container {
    padding: 20px 20px 20px 20px;
    background-color: #fefefe;
}

.pd-top-100 {
    padding-top: 100px;
}

.pd-bottom-100 {
    padding-bottom: 100px;
}

.pd-top-40 {
    padding-top: 40px;
}
/* .pd-top-37 {
    padding-top: 37px !important;
} */
.mg-top-40 {
    margin-top: 40px;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}

.font-10 {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0.5px !important;
}

.font-12 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.5px !important;
}

.font-12-thin {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.5px !important;
}

.font-14 {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0.5px !important;
}

.font-16 {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 18px !important;
    letter-spacing: 0.5px !important;
}

.font-16-400 {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18.38px !important;
    letter-spacing: 0.5px !important;
}

.font-16-bold-500 {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0.5px !important;
}

.font-18 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 21px !important;
    letter-spacing: 0.5px !important;
}

.font-20 {
    font-size: 20px !important;
    font-weight: 300 !important;
    font-style: normal !important;
    line-height: 23px !important;
    letter-spacing: 0.5px !important;

    color: #000000;
}

.font-20-700 {
    font-size: 20px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 22px !important;
    letter-spacing: 0.5px !important;
    color: #ffffff;
}

.font-20-400 {
    font-size: 20px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 23px !important;
    letter-spacing: 0.5px !important;

    color: #000000;
}

.font-21 {
    font-size: 21px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
}

.font-23 {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.5px;
}

.font-24 {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
}

.font-24-700 {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
}

.font-24-400 {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
}

.font-25 {
    font-size: 25px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
}

.font-32 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 37px !important;
    letter-spacing: 0.5px !important;
}
.font-41 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 41px !important;
    line-height: 57px !important;
    letter-spacing: 0.5px !important;
}

.font-48 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 48px !important;
    line-height: 57px !important;
    letter-spacing: 0.5px !important;
}
.font-50 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 50px !important;
    line-height: 57px !important;
    letter-spacing: 0.5px !important;
}

.font-60 {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 60px !important;
    line-height: 69px !important;
    letter-spacing: 0.5px !important;
}

.font-64-700 {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 60px !important;
    line-height: 69px !important;
    letter-spacing: 0.5px !important;
}

.font-80 {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 80px !important;
    line-height: 88px !important;
    /* identical to box height, or 110% */
    letter-spacing: 0.5px !important;
}

.font-80-700 {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 80px !important;
    line-height: 88px !important;
    /* identical to box height, or 110% */
    letter-spacing: 0.5px !important;
}

.font_mob24_Lap_32 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 37px !important;
    letter-spacing: 0.5px !important;
}

@media only screen and (max-width: 600px) {
    .font_mob24_Lap_32 {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        line-height: 27px !important;
        letter-spacing: 0.5px !important;
    }
}

.mg-left-10 {
    margin-left: 10px;
}
.mg-left-28 {
    margin-left: 28px;
}
.mg-left-2 {
    margin-left: 2px;
}
.mg-top-40 {
    margin-top: 40px !important;
}

.mg-right-15 {
    margin-right: 15px;
}

.br-20 {
    border-radius: 20px !important;
}

.br-16 {
    border-radius: 16px !important;
}

.br-25 {
    border-radius: 25px !important;
}

.br-24 {
    border-radius: 24px !important;
}

.br-73 {
    border-radius: 73px !important;
}

.br-50 {
    border-radius: 50px !important;
}

.border-none {
    border: none !important;
}

.w-auto {
    width: auto !important;
}

.border-bottom-black {
    border-bottom: 2px solid #201627 !important;
    border: none;
    border-collapse: separate;
}

.nft-desc-container {
    padding-left: 120px;
    padding-right: 120px;
}

.profile-container {
    padding-left: 88px;
    padding-right: 88px;
    padding-bottom: 88px;
}

.nft-desc-title {
    font-size: 64px;
    font-weight: 700;
    line-height: 73.54px;
    text-align: left;
    letter-spacing: 0.5px;
    word-wrap: normal;
}
@media screen and (max-width: 786px) {
    .nft-desc-title {
        font-size: 8vw;
        line-height: 9vw;
    }
}

.nft-buy-btn {
    /* White */
    justify-content: center;
    align-items: center;
    background: #fefefe;
    box-shadow: 0px 6px 24px 17px rgba(0, 0, 0, 0.08);
    border-radius: 35px;
}

.nft-detail-btn {
    border: 1px solid #ffe074;
    border-radius: 8px;
}

/* react-select  */
.css-tlfecz-indicatorContainer {
    color: #000000 !important;
    text-align: left !important;
    padding-left: 0px !important;
}
/* 
.css-1uccc91-singleValue {
    color: #000000 !important;
    font-weight: 600 !important;
    padding-right: 0px !important;
 }

 .css-g1d714-ValueContainer {
    text-align: right !important; 
    padding: 2px 0px 2px 8px !important;
    justify-content: end !important;
 }
.css-1okebmr-indicatorSeparator {
    display: none !important;
}  

.css-yk16xz-control {
    border: none !important;
} */

/* .css-1pahdxg-control {
    border: none !important;
    box-shadow: 0em !important;
    outline: none !important;
    background: transparent !important;
} */

a {
    text-decoration: none;
}

@media screen and (min-width: 40em) {
    .page.lists-show nav {
        text-align: left;
    }
}

@media only screen and (max-width: 765px) {
    .nft-desc-container {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .postion-sticky {
        position: sticky !important;
        z-index: 10 !important;
    }
    .profile-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    /* .nft-desc-title {
     font-size: 25px;
     font-weight: 600;
     line-height: 25.54px;
     text-align: left;
     letter-spacing: 0.5px;
   } */
}

@media only screen and (max-width: 1092px) {
    .nft-desc-container {
        padding-left: 50px;
        padding-right: 50px;
    }
    .profile-container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .nft-desc-container {
        padding-left: 50px;
        padding-right: 50px;
    }
    .profile-container {
        padding-left: 30px;
        padding-right: 30px;
    }
}
/* 
.gradient-border {
    border: 1px solid
        conic-gradient(
            from 155.86deg at 50% 50%,
            #df55ea 0deg,
            #e34dcc 71.25deg,
            #f9c17b 180deg,
            #68d0e0 275.62deg,
            #df55ea 360deg
        );
} */

.gradient-border {
    position: relative;
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 1px;
    background: linear-gradient(0deg, #68d0e0, #f9c17b, #df55ea) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.gradient-border-2px {
    position: relative;
}

.gradient-border-2px::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 2px;
    background: linear-gradient(0deg, #68d0e0, #f9c17b, #df55ea) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.verifiedDivWidth {
    width: 175px;
}

@media screen and (max-width: 768px) {
    .verifiedDivWidth {
        width: auto;
    }
}

.object-fit {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.p-right-10 {
    padding-right: 10px;
}

.p-left-10 {
    padding-left: 10px;
}

/* @media only screen and (min-width: 1400px) {
    .nft-desc-container {
      padding-left : 50px;
      padding-right: 50px;
    }
  } */

/* Slider Designs */
.slick-dots {
    display: block !important;
}
.slick-dots li button::before {
    color: #201627 !important;
    font-size: 12px !important;
    opacity: 1 !important;
    padding-top: 36px !important;
}
.slick-dots li.slick-active button::before {
    color: #a12ceb !important;
}

.slick-prev::before,
.slick-next::before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0;
    display: none;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* file drop css */

.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 240px;
    width: 100%;
}

.file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #ff6e40;
    box-shadow: 0 0 13px 3px #ff6e40;
}

.slick-arrow img {
    border: 1px solid #bdbdbd;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 6px 20px 12px !important;
    transition: all 0.2s ease-in-out;
}

.slick-arrow img:hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 20px 12px !important;
    transform: scale(1.1);
}

.addeventatc {
    border-radius: 25px !important;
}

.addeventatc:hover {
    background-color: #e54f4f !important;
    color: #fff !important;
    border: 1px solid #fff !important;
}

.addeventatc:hover .calendarImage {
    filter: invert(99%) sepia(5%) saturate(79%) hue-rotate(326deg)
        brightness(120%) contrast(100%);
}

.addeventatc .addeventatc_icon {
    display: none !important;
}

.Snackbar_snackbar-wrapper__ocbPJ {
    z-index: 5 !important;
}

#slide-right {
    -webkit-animation: slide-right 0.5s forwards;
    animation: slide-right 0.8s forwards;
}

@keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }

    50% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

#slide-left {
    -webkit-animation: slide-left 0.5s forwards;
    animation: slide-left 0.8s forwards;
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }

    50% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
