.container {
    position: absolute;
    min-height: 100vh;
}

.children {
    min-height: 100vh;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backToHome {
    margin: 3rem 0 0;
}
