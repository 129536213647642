/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap'); */

.heading2Xl {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
}

.colorInherit {
    color: inherit;
}

.padding1px {
    padding-top: 1px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.listItem {
    margin: 0 0 1.25rem;
}

.lightText {
    color: #666;
}

.alignItemsCenter {
    display: flex;
    align-items: center;
}

.logoIcon {
    left: 0%;
    padding-left: 1rem;
    top: 0%;
    bottom: 0%;
}

.logoText {
    left: 0%;
    right: 0%;
    top: 0.2rem;
    bottom: -0.02%;
}

.logoPosition {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 224px;
    height: 56px;
    align-items: center;
}

.overlayLogoPosition {
    display: flex;
    align-items: center;
    width: 224px;
    height: 100%;
    margin-left: 1rem;
}

@media screen and (max-width: 425px) {
    .logoPosition {
        width: 128px;
        height: 32px;
    }

    .logoIcon {
        width: 20px;
        height: 40px;
    }

    .logoText {
        width: 125px;
        height: 56px;
    }

    .overlayLogoPosition {
        width: 128px;
        height: 32px;
    }
}

.hamburgerButton {
    display: flex;
    border: none;
    cursor: pointer;
    background: #ffffff;
    padding: 0px;
}

.profileLoginImgHeader {
    display: flex;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    overflow: hidden;
    object-fit: cover;
}

.menuCloseButton {
    border: none;
    cursor: pointer;
    background: #201627;
    margin-top: 13px;
}
