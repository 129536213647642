.mainDiv {
    background: #201627;
    padding: 64px 32px;
    padding-bottom: 32px;
}

@media screen and (max-width: 767px) {
    .mainDiv {
        background: #201627;
        padding: 56px 32px;
    }
}

.footerStyle {
    background-color: #201627;
    /* font-size: 20px; */
    color: white;
    border-top: 1px solid #e7e7e7;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    /* position:absolute; */
    left: 0;
    bottom: 0;
    right: 0;
    /* height: 60px; */
    width: 100%;
}

.copyrightText {
    /* font-family: Ubuntu; */
    font-style: normal;
    font-weight: 300;
    font-size: 12px !important;
    line-height: 14px;
    /* identical to box height */

    letter-spacing: 0.5px;

    /* White */

    color: #fefefe;
}

.termsAndConditions {
    /* font-family: Ubuntu; */
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    width: 185px;
    /* identical to box height */

    letter-spacing: 0.5px;

    /* White */

    color: #fefefe;

    display: flex;
    justify-content: space-between;
}

.marginIcon {
    margin-right: 24px;
}

.lineBreakContainer {
    padding: 112px 0 24px 0;
}

@media screen and (max-width: 767px) {
    .lineBreakContainer {
        padding: 56px 0 24px 0;
    }
}

.lineBreak {
    border: 1px solid #f1eef1;
}
