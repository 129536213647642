.logoText {
    margin-top: 20px;
}

.logoPosition {
    display: flex;
    align-items: center;

    max-width: 320px;
    max-height: 80px;
}
